import React from 'react';
import styled from 'styled-components';
import {graphql, useStaticQuery} from 'gatsby';
import {H1, TextContainer} from '../components/atoms';
import {PageContent} from '../components/organisms/Layout';
import {NavigationContext} from '../services/navigation';
import GridComponentContainer from '../components/atoms/GridComponentContainer';
import {colors} from '../styles/theme';
import {Intro} from '../components/organisms';

const TitleContainer = styled(GridComponentContainer)`
  ${H1} {
    color: ${colors.blue};
  }
`;

const NotFoundPage = () => {
  const {setLocation} = React.useContext(NavigationContext);
  React.useEffect(() => {
    setLocation(window.location.pathname);
  }, []);
  const data = useStaticQuery(graphql`
    query {
      strapi {
        pageNotFound404 {
          body
          title
        }
      }
    }
  `);
  return (
    <PageContent>
      <TitleContainer fromColumn={3}>
        <TextContainer>
          <H1>{data.strapi.pageNotFound404?.title}</H1>
        </TextContainer>
      </TitleContainer>
      <Intro
        intro={{
          text: {body: data.strapi.pageNotFound404?.body},
          startingColumn: 3,
        }}
      />
    </PageContent>
  );
};

export default NotFoundPage;
